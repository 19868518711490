import { useGeolocation } from '@vueuse/core';
import { useUserStore } from '@/store/useUserStore';
import { useMqtt } from '@/Composables/useMqtt';
import { Ref, ref } from 'vue';
const updateIntervalHandlerId: Ref<number | null> = ref(null);

export function useUpdateUserGeolocation() {
    const { coords } = useGeolocation();
    const { sendMqttMessage } = useMqtt();

    function makeMQTTMessageFromCoordinates(coordinates: GeolocationCoordinates): string {
        const { user } = useUserStore();

        if (!user) {
            throw new Error('The user must be defined to send geolocation coordinates with MQTT.');
        }

        return JSON.stringify({
            lat: coordinates.latitude,
            long: coordinates.longitude,
            user_id: user.id,
        });
    }

    function buildTopicNameForUser(): string {
        const { user } = useUserStore();

        if (!user?.id) {
            throw new Error('The user id must be defined to build its geolocation topic name.');
        }

        const topic = import.meta.env.VITE_MQTT_ITEAM_POSITION_TOPIC_NAME as string;

        return topic.replace(/{user_id}/g, `${user.id}`);
    }

    function startUpdatingUserGeolocation(): void {
        stopUpdatingUserGeolocation();

        const topic = buildTopicNameForUser();

        const sendMqttMessageCallback = () => {
            const userCoordinates = coords.value;

            if (userCoordinates.latitude === null || userCoordinates.longitude === null) {
                console.error(
                    "The user's geolocation cannot be updated because the browser geolocation is not available"
                );
                return;
            }

            const message = makeMQTTMessageFromCoordinates(userCoordinates);

            sendMqttMessage(topic, message);
        };

        sendMqttMessageCallback();

        updateIntervalHandlerId.value = setInterval(
            sendMqttMessageCallback,
            (import.meta.env.VITE_MQTT_GEOLOCATION_UPDATE_INTERVAL as number) ?? 30000
        );
    }

    function stopUpdatingUserGeolocation(): void {
        const handlerId = updateIntervalHandlerId.value;

        if (handlerId !== null) {
            clearInterval(handlerId);
        }
    }

    return {
        startUpdatingUserGeolocation,
        stopUpdatingUserGeolocation,
    };
}
