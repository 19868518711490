import { defineStore } from 'pinia';
import { ref } from 'vue';
import { User, UserLogin, UserResetPassword } from '@/types/User';
import { fetchUser, postForgotPassword, postLogin, postLogout, postResetPassword } from '@/api/user';
import router from '@/router';
import { presentToast } from '@/utils/presentToast';
import { useGlobalStore } from '@/store/useGlobalStore';
import { useAlertStore } from '@/store/useAlertStore';
import { deleteToken } from 'firebase/messaging';
import { messaging } from '@/firebase';
import { useUpdateUserGeolocation } from '@/Composables/useUpdateUserGeolocation';

export const useUserStore = defineStore(
    'userStore',
    () => {
        const { apiCall, setFirebaseToken } = useGlobalStore();
        const { setAlert } = useAlertStore();
        const { getCurrentAlertAssociated } = useAlertStore();
        const user = ref<User | null>(null);

        const login = async (userCredentials: UserLogin) => {
            await apiCall(async () => {
                const { data } = await postLogin(userCredentials);
                setUser(data as User);
                await router.replace('/tabs/no-alert');
                await getCurrentAlertAssociated();
                await setFirebaseToken();
            });
        };

        const logout = async () => {
            await apiCall(async () => {
                await postLogout();
                await router.replace('/tabs/no-alert');
                await removeAuthentificationData();
                await deleteToken(messaging);
            });
        };

        const removeAuthentificationData = async () => {
            setUser(null);
            setAlert(null);
            await router.replace('/login');
        };

        const getUser = async () => {
            await apiCall(async () => {
                const { data } = await fetchUser();
                setUser(data as User);
            });
        };
        const forgotPassword = async (email: string) => {
            await apiCall(async () => {
                const { data } = await postForgotPassword(email);
                await presentToast(data.status, 'success');
            });
        };

        const resetPassword = async (userCredentials: UserResetPassword) => {
            if (!userCredentials.token) {
                await router.push('/forgot-password');
                await presentToast('Token is required', 'danger');
                return;
            }

            if (userCredentials.password !== userCredentials.password_confirmation) {
                await presentToast('Password and password confirmation must be the same', 'danger');
                return;
            }

            await apiCall(async () => {
                const { data } = await postResetPassword(userCredentials);
                await presentToast(data.status, 'success');
                await router.replace('/login');
            });
        };

        const setUser = (newUser: User | null) => {
            user.value = newUser;
            if (newUser) {
                if ((import.meta.env.VITE_MQTT_ENABLE_GEOLOCATION_UPDATE as string) === 'false') {
                    console.warn('User geolocation is disabled');
                    return;
                }
                // Ask for geolocation authorization when the user is logged in.
                const { startUpdatingUserGeolocation } = useUpdateUserGeolocation();
                startUpdatingUserGeolocation();
            } else {
                if ((import.meta.env.VITE_MQTT_ENABLE_GEOLOCATION_UPDATE as string) === 'false') {
                    console.warn('User geolocation is disabled');
                    return;
                }
                const { stopUpdatingUserGeolocation } = useUpdateUserGeolocation();
                stopUpdatingUserGeolocation();
            }
        };

        return {
            user,
            getUser,
            setUser,
            login,
            logout,
            forgotPassword,
            resetPassword,
            removeAuthentificationData,
        };
    },
    { persist: true }
);
