import mqtt, { IConnackPacket, MqttClient, OnErrorCallback, Packet } from 'mqtt';
import { ref } from 'vue';
import { QoS } from 'mqtt-packet';
import { ErrorWithReasonCode } from 'mqtt/src/lib/shared';

const mqttClient = ref<MqttClient | null>(null);
const enableLogs = ref<boolean>((import.meta.env.VITE_MQTT_ENABLE_LOGS as boolean) ?? false);

export type CustomOnConnectCallback = (mqttClient: MqttClient, packet: IConnackPacket) => void;

export function useMqtt() {
    function initMqttClient(
        onConnectCallback: CustomOnConnectCallback,
        onErrorCallback: OnErrorCallback | null = null
    ): MqttClient {
        const brokerUrl = import.meta.env.VITE_MQTT_BROKER_URL as string;
        const username = import.meta.env.VITE_MQTT_BROKER_USERNAME as string;
        const password = import.meta.env.VITE_MQTT_BROKER_PASSWORD as string;

        const mqttClientInstance = mqtt.connect(brokerUrl, {
            username: username,
            password: password,
            connectTimeout: 5 * 1000, // 5 seconds
            path: '/mqtt',
        });

        mqttClientInstance.on('connect', (packet) => onConnectCallback(mqttClientInstance, packet));
        mqttClientInstance.on(
            'error',
            onErrorCallback ??
                ((error) => {
                    console.error('MQTT connection error :', error);
                })
        );

        mqttClient.value = mqttClientInstance;

        return mqttClientInstance;
    }

    function sendMqttMessage(
        topic: string,
        message: string,
        qos: QoS = 1,
        retain: boolean = false,
        onErrorCallback?: (error: Error | ErrorWithReasonCode) => void,
        onSuccessCallback?: (packet?: Packet) => void
    ): void {
        const publishMessageCallback = (mqttClient: MqttClient) => {
            mqttClient.publish(topic, message, { qos: qos, retain: retain }, (error, packet) => {
                if (error) {
                    if (enableLogs.value) {
                        console.error('MQTT publish error :', error);
                    }
                    if (onErrorCallback) {
                        onErrorCallback(error);
                    }
                } else {
                    if (enableLogs.value) {
                        console.log(`MQTT message published on ${topic}: ${message}`);
                    }
                    if (onSuccessCallback) {
                        onSuccessCallback(packet);
                    }
                }
            });
        };

        if (!mqttClient.value) {
            initMqttClient(publishMessageCallback);
            return;
        }

        const mqttClientInstance = mqttClient.value;

        if (!mqttClientInstance) {
            throw new Error('An error occurred when retrieving the MQTT client instance');
        }

        publishMessageCallback(mqttClientInstance);
    }

    return {
        sendMqttMessage,
    };
}
